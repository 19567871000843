import axios from 'axios';
import { useState } from 'react';
import './Home.css';

function Home() {
    const [type, setType] = useState('random');
    const [source, setSource] = useState('');
    const [path, setPath] = useState('');
    const [shorted, setShorted] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const classError = error ? 'inputError' : '';

    const handleShare = () => {
        navigator.share({ url: shorted });
    }

    const handleCopy = () => {
        const copy = document.getElementById("copy") as HTMLInputElement;
        copy?.select();
        copy?.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copy?.value || '');
    }

    const handleReset = () => {
        setSource('');
        setPath('');
        setShorted('');
        setError('');
        setLoading(false);
    }

    const handleSubmit = () => {
        if(!source){
            setError('Preencha a URL de destino corretamente.');
            return;
        }

        if(type === 'custom' && !path){
            setError('Preencha o nome do seu link personalizado.');
            return;
        }

        setLoading(true);

        const payload = {
            url_original: source.includes('http') ? source : `http://${source}`,
            ...(type === 'custom' && {
                url_shortened: path
            })
        };
        axios.post('https://api.gulo.me/url/create', payload, {
            headers: {
                'Accept': 'application/json',
            }
        })
            .then(resp => {
                const url = resp.data.url;
                setSource('');
                setPath('');
                setShorted(url);
            })
            .catch(err => {
                setError(err.response.data.message || 'Houve um problema. Tente novamente!');
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <header>
                <h1 className="header__logo">gulo</h1>
                <h2 className="header__subtitle">Encurtador de links<br/>gratuito e personalizado</h2>
            </header>
            <section>
                {!shorted && (
                    <>
                        <div className="customLink">
                            <div className={type === 'random' ? 'active' : ''} onClick={() => setType('random')}>Aleatório</div>
                            <div className={type === 'custom' ? 'active' : ''} onClick={() => setType('custom')}>Personalizado</div>
                        </div>
                        {type === 'random' ? (
                            <div className={`inputLink ${classError}`}>
                                <input 
                                    type="text" 
                                    placeholder="URL destino (ex: teste.com/abc123)" 
                                    autoCapitalize="off"
                                    value={source}
                                    onChange={e => {
                                        setSource(e.target.value);
                                        setError("");
                                    }}
                                    autoFocus
                                />
                                <button onClick={handleSubmit}>
                                    {loading ? <div className="spinner" /> : 'Encurtar'}
                                </button>
                            </div>
                        ): (
                            <div className={`inputLink inputLinkCustom ${classError}`}>
                                <div className='inputsWrapper'>
                                    <input 
                                        type="text" 
                                        placeholder="URL destino (ex: teste.com/abc123)" 
                                        autoCapitalize="off"
                                        value={source}
                                        onChange={e => {
                                            setSource(e.target.value);
                                            setError("");
                                        }}
                                    />
                                    <div className="inputLinkFormatted">
                                        gulo.me/
                                        <input 
                                            type="text" 
                                            placeholder="algum-nome" 
                                            autoCapitalize="off"
                                            value={path}
                                            onChange={e => {
                                                setPath(e.target.value);
                                                setError("");
                                            }}
                                        />
                                    </div>
                                </div>
                                <button onClick={handleSubmit}>
                                    {loading ? <div className="spinner" /> : 'Encurtar'}
                                </button>
                            </div>
                        )}
                        <div className="feedbackError">{error || ` `}</div>
                    </>
                )}
                {shorted && (
                    <>
                        <div>Seu link encurtado está pronto! 🚀👇</div>
                        <div className="feedbackSuccess">
                            <a href={shorted} target="_blank" className="shorted" rel="noreferrer">
                                {shorted}
                            </a>
                        </div>
                        <div className="shareOptions">
                            <button onClick={handleCopy}>Copiar</button>
                            <button onClick={handleShare}>Compartilhar</button>
                            <button onClick={handleReset}>Criar novo</button>
                        </div>
                        <input id="copy" type="text" style={{ display: 'none' }} value={shorted} onChange={() => null} />
                    </>
                    
                )}
            </section>
            <footer>
                <div>
                    <a href="mailto:devgustavoalves@gmail.com">Denunciar link</a>
                </div>
                <div className='footer__credits'>
                    Feito por <a href="https://www.linkedin.com/in/marcelonralves/">Marcelo</a> e <a href="https://www.linkedin.com/in/gustavoalvesm/">Gustavo</a>
                </div>
            </footer>
        </>
    );
}

export default Home;